<template>
    <div>
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-card-title class="white--text pa-4 primary">
                <v-row>
                    <v-col cols="9">
                        <v-text-field v-model="search" label="Buscar:" dark flat hide-details clearable outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="white" class="text--primary" dark v-bind="attrs" v-on="on" fab small>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-toolbar-title>{{tituloUsuario}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="cancelarUsuario()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-list three-line subheader>
                            <v-container>
                                <v-form ref="frm" @submit.prevent="submit" lazy-validation>
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.identificacion"
                                                    :rules="rules.identificacion" prepend-inner-icon="mdi-numeric"
                                                    label="N.Identificación*" outlined required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-dialog ref="dialog" v-model="modal"
                                                    :return-value.sync="form.nacimiento" persistent width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="form.nacimiento"
                                                            :rules="rules.nacimiento"
                                                            prepend-inner-icon="mdi-calendar-month"
                                                            label="Fecha de nacimiento*" readonly v-bind="attrs"
                                                            v-on="on" outlined required>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker year-icon="mdi-calendar-blank"
                                                        prev-icon="mdi-skip-previous" next-icon="mdi-skip-next"
                                                        v-model="form.nacimiento" scrollable :max="fecha"
                                                        :show-current="fecha" locale="es">
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary" @click="modal = false">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn text color="primary"
                                                            @click="$refs.dialog.save(form.nacimiento)">
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.nombre" :rules="rules.nombre"
                                                    prepend-inner-icon="mdi-format-text" label="Nombres*" outlined
                                                    required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.apellido" :rules="rules.apellido"
                                                    prepend-inner-icon="mdi-format-text" label="Apellidos*" outlined
                                                    required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.direccion"
                                                    prepend-inner-icon="mdi-map-marker-radius" label="Dirección"
                                                    outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.telefono"
                                                    prepend-inner-icon="mdi-card-account-phone" label="Télefono"
                                                    type="number" outlined></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="form.email" :rules="rules.email"
                                                    prepend-inner-icon="mdi-email" label="E-mail*" outlined required>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete @change="listarMunicipio()" v-model="departamento"
                                                    :items="departamentos" :rules="rules.departamento"
                                                    item-text="nombre" item-value="Uid" prepend-inner-icon="mdi-city"
                                                    label="Deparmento*" outlined required>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete v-model="form.municipio" :items="municipios"
                                                    :rules="rules.municipio" item-value="Uid"
                                                    prepend-inner-icon="mdi-home-modern" item-text="nombre"
                                                    label="Municipio*" outlined required>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete @change="listarTratamiento()" v-model="tipoTratamiento"
                                                    :items="tiposTratamientos" :rules="rules.tipoTratamiento"
                                                    item-text="nombre" item-value="Uid"
                                                    prepend-inner-icon="mdi-account-tie-voice"
                                                    label="Tipo de Tratamiento*" outlined required>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete v-model="form.tratamiento" :items="tratamientos"
                                                    :rules="rules.tratamiento" item-value="Uid" item-text="nombre"
                                                    prepend-inner-icon="mdi-account-tie" label="Tratamiento*" outlined
                                                    required>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete @change="listarSede()" v-model="empresa"
                                                    :items="empresas" :rules="rules.empresa" item-value="Uid"
                                                    item-text="nombre" prepend-inner-icon="mdi-home-city"
                                                    label="Empresa*" outlined required>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete v-model="form.sede" :items="sedes" :rules="rules.sede"
                                                    item-value="Uid" item-text="nombre"
                                                    prepend-inner-icon="mdi-home-map-marker" label="Sede*" outlined
                                                    required>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete v-model="form.grupo" :items="grupos"
                                                    :rules="rules.grupo" item-value="Uid" item-text="nombre"
                                                    prepend-inner-icon="mdi-account-group" label="Grupo*" outlined
                                                    required>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" sm="6">
                                                <v-autocomplete v-model="form.cargo" :items="cargos"
                                                    :rules="rules.cargo" item-value="Uid" item-text="nombre"
                                                    prepend-inner-icon="mdi-account-hard-hat" label="Cargo*" outlined
                                                    required>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete v-model="form.rol" :items="roles" :rules="rules.rol"
                                                    item-value="Uid" item-text="nombre"
                                                    prepend-inner-icon="mdi-account-convert" label="Rol*" outlined
                                                    required>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.usuario" :rules="rules.usuario"
                                                    prepend-inner-icon="mdi-account-circle" label="Usuario*" outlined
                                                    required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.contrasena"
                                                    :rules="[rules.contrasena.password, rules.contrasena.length(8)]"
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'" prepend-inner-icon="mdi-key"
                                                    label="Contraseña" @click:append="show = !show" outlined>

                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.contrasenaC"
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :rules="[rules.contrasenaC.compare(form.contrasena), rules.contrasena.length(8)]" :type="show ? 'text' : 'password'"
                                                    prepend-inner-icon="mdi-key-change" label="Contraseña"
                                                    @click:append="show = !show" outlined></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.telefonoC" type="number"
                                                    prepend-inner-icon="mdi-card-account-phone"
                                                    label="Télefono Corporativo" outlined></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field v-model="form.emailC" :rules="rules.emailC"
                                                    prepend-inner-icon="mdi-email" label="E-mail Corporativo*" outlined
                                                    required></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-switch @change="estadoUsuario()" v-model="form.estadoU"
                                                    color="primary" label="Estado de usuario" inset></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" class="m-4" @click="cancelarUsuario()">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!formIsValid" color="success" class="m-4" type="submit">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-container>
                        </v-list>
                    </v-card>
                </v-dialog>
            </v-card-title>

            <v-card-text>
                <v-row class="pa-4" justify="space-between">
                    <v-col cols="12" md="5">
                        <v-treeview :active.sync="active" :items="items" :load-children="listarUsuario"
                            :open.sync="open" activatable color="warning" open-on-click transition :search="search"
                            class="scroll">
                            <template v-slot:prepend="{ item }">
                                <v-icon v-if="item.children" v-text="
                                    `mdi-${item.id === 0 ? 'home-variant' : 'folder-network'}`
                                "></v-icon>
                                <v-icon v-if="!item.children">
                                    mdi-account
                                </v-icon>
                            </template>
                        </v-treeview>
                    </v-col>
                    <v-divider vertical></v-divider>

                    <v-col class="d-flex text-center">
                        <v-scroll-y-transition mode="out-in">
                            <div v-if="!selected" class="text-h6 grey--text text--lighten-1 font-weight-light"
                                style="align-self: center;">
                                Select a User

                            </div>
                            <v-card v-else :key="a.id" class="pt-6 mx-auto" flat max-width="800">
                                <v-card-text>
                                    <v-btn v-if="editPerfil.permiso == 0" class="mx-2 mb-5" fab dark large color="cyan"
                                        absolute bottom right @click="editarUsuario(usuarioU)">
                                        <v-icon dark>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                    <v-avatar v-if="usuarioU.avatar" size="75px">
                                        <v-img :src="`${url_api}/files/foto/${usuarioU.usuario}/${usuarioU.avatar}`">
                                        </v-img>
                                    </v-avatar>
                                    <v-avatar tile v-else size="75px">
                                        <v-img :src="`${avatar}`" contain></v-img>
                                    </v-avatar>
                                    <h3 class="text-h5 my-2">
                                        {{ usuarioU.nombre + " " + usuarioU.apellido }}
                                    </h3>
                                    <div class="text-center mb-2 text-h6" tag="strong">
                                        {{ usuarioU.cargo }}
                                    </div>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-row class="text-left" tag="v-card-text">
                                    <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                                        Empresa:
                                    </v-col>
                                    <v-col>{{ usuarioU.empresa }}</v-col>
                                    <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                                        Sede:
                                    </v-col>
                                    <v-col>{{ usuarioU.sede }}</v-col>
                                    <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                                        Telefono:
                                    </v-col>
                                    <v-col>
                                        {{ usuarioU.telefono }}
                                    </v-col>
                                    <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                                        Telefono Corporativo:
                                    </v-col>
                                    <v-col>{{ usuarioU.telefono_corporativo }}</v-col>
                                    <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                                        Email:
                                    </v-col>
                                    <v-col>{{ usuarioU.email }}</v-col>
                                    <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                                        Email Corporativo:
                                    </v-col>
                                    <v-col>{{ a.email_corporativo }}</v-col>
                                    <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                                        Rol:
                                    </v-col>
                                    <v-col>{{ usuarioU.rol }}</v-col>
                                    <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                                        Estado:
                                    </v-col>
                                    <v-col v-if="editPerfil.permiso == 0">
                                        <v-btn v-if="usuarioU.estado == 0" rounded small color="red" dark
                                            @click="estado(usuarioU)">
                                            Inactivo
                                        </v-btn>
                                        <v-btn v-if="usuarioU.estado == 1" rounded small color="success" dark
                                            @click="estado(usuarioU)">
                                            Activo
                                        </v-btn>
                                    </v-col>
                                    <v-col v-else>
                                        <p v-if="usuarioU.estado == 0">
                                            Inactivo
                                        </p>
                                        <p v-if="usuarioU.estado == 1">
                                            Activo
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-scroll-y-transition>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<style>
    .foto div .v-input__icon {
        align-items: center;
        display: inline-flex;
        height: 100px;
        flex: 1 0 auto;
        justify-content: center;
        min-width: 100px;
        width: 100px;
    }

    .foto .v-input__prepend-outer {
        margin-right: 0;
    }

    .foto {
        padding-top: 0px;
        margin-top: 0px;
    }

    .scroll {
        display: block;
        height: 700px;
        overflow-y: scroll;
        scroll-behavior: smooth;
    }
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import image from "@/assets/Perfil.png";

    const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    export default {
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            modal: false,
            editPerfil: null,
            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,

            validacion: true,
            letas: false,
            numeros: false,
            caracter: false,

            UidUsuario: null,
            active: [],
            avatar: image,
            open: [0],
            search: null,
            users: [],
            dialog: false,

            rules: {
                identificacion: [
                    val => !!val || "La identificacion es requerida",
                    val => (val >= 100000) || "La identificacion es requerida debe tener minimo 6 dígitos"
                ],
                email: [
                    (val) => !!val || "E-mail is required",
                    (val) => /.+@.+\..+/.test(val) || "E-mail es requerido",
                ],
                emailC: [
                    (val) => !!val || "E-mail is required",
                    (val) => /.+@.+\..+/.test(val) || "E-mail es requerido",
                ],
                contrasena: {
                    password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
                        'La contraseña debe contener una letra mayúscula, un carácter numérico y un carácter especial.',
                    length: len => v => (v || '').length >= len ||
                        `La contraseña debe tener minimo ${len} caracteres.`,
                },
                contrasenaC: {
                    compare: contrasena => v => (v || '') == contrasena ||
                        `Por favor verifica que la contraseña coinsida.`,
                    length: len => v => (v || '').length >= len ||
                        `La contraseña debe tener minimo ${len} caracteres.`,
                },
                nombre: [(val) => !!val || "El nombre es requerido"],
                usuario: [(val) => !!val || "El usuario es requerido"],
                nacimiento: [(val) => !!val || "La fecha de nacimiento es requerida"],
                apellido: [(val) => !!val || "El apellido es requerido"],
                tipoTratamiento: [
                    (val) => !!val || "El tipo de tratamiento es requerido",
                ],
                tratamiento: [(val) => !!val || "El tratamiento es requerido"],
                departamento: [(val) => !!val || "El departamento es requerido"],
                municipio: [(val) => !!val || "El municipio es requerido"],
                cargo: [(val) => !!val || "El cargo es requerido"],
                sede: [(val) => !!val || "La sede es requerida"],
                empresa: [(val) => !!val || "La empresa es requerida"],
                rol: [(val) => !!val || "El rol es requerido"],
                grupo: [(val) => !!val || "El grupo es requerido"],
            },
            fecha: (new Date().getFullYear() - 18) + ((new Date(Date.now() - (new Date())
                .getTimezoneOffset() * 60000)).toISOString().substr(4, 6)),
            show: false,
            show1: false,
            tratamientos: [],
            departamentos: [],
            usuarios: [],
            usuarioU: [],
            departamento: null,
            tiposTratamientos: [],
            tipoTratamiento: null,
            municipios: [],
            empresas: [],
            empresa: null,
            cargos: [],
            sedes: [],
            roles: [],
            grupos: [],
            a: [],
            snackbar: false,
            editedUsuarioIndex: -1,
            form: {
                // foto: null,
                nombre: "",
                contrasena: "",
                contrasenaC: "",
                apellido: "",
                direccion: "",
                email: "",
                emailC: "",
                usuario: "",
                tratamiento: null,
                municipio: null,
                cargo: null,
                sede: null,
                rol: null,
                grupo: null,
                identificacion: null,
                telefono: null,
                telefonoC: null,
                nacimiento: null,
                estadoU: 0,
            },
            defaultForm: {
                // foto: null,
                nombre: "",
                contrasena: "",
                contrasenaC: "",
                apellido: "",
                direccion: "",
                email: "",
                emailC: "",
                usuario: "",
                tratamiento: null,
                municipio: null,
                cargo: null,
                sede: null,
                rol: null,
                grupo: null,
                identificacion: null,
                telefono: null,
                telefonoC: null,
                nacimiento: null,
                estadoU: 0,
            },
        }),
        computed: {
            ...mapState(["token", "usuario"]),
            tituloUsuario() {
                return this.editedUsuarioIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
            },

            items() {
                return [{
                    id: 0,
                    name: "Empresas",
                    children: this.users,
                }, ];
            },

            selected() {
                if (!this.active.length) return undefined;
                const id = this.active[0];
                let retur = false;
                let aa = []
                this.users.forEach(element => {
                    element.children.forEach(ele => {
                        if (ele.id == id) {
                            aa = element.children.find((user) => user.id === id);
                            return this.usuarioU = this.usuarios.find((user) => user.Uid == aa.id)
                        }
                    })
                    retur = true;
                })
                return retur
            },

            formIsValid() {
                return (
                    this.form.identificacion &&
                    this.form.nombre &&
                    this.form.apellido &&
                    this.form.tratamiento &&
                    this.form.municipio &&
                    this.form.nacimiento &&
                    this.form.email &&
                    this.form.usuario &&
                    this.form.emailC &&
                    this.form.sede &&
                    this.form.grupo &&
                    this.form.rol &&
                    this.form.cargo
                );
            },
        },
        watch: {
            // modal usuario
            dialog(val) {
                val || this.cancelarUsuario()
            },
        },
        created() {
            this.editPerfil = JSON.parse(this.usuario)
            this.listarTipoTrat();
            this.listarDepartamento();
            this.listarCargo();
            this.listarEmpresa();
            this.listarRol();
            this.listarGrupo();
        },

        methods: {
            ...mapActions(['cerrarSesion']),

        
            estadoUsuario() {
                if (!this.form.estadoU) {
                    this.form.estadoU = 0;
                }
            },

            async listarUsuario(item) {
                await pause(1500);
                try {
                    const res = await fetch(`${this.url_api}/persona`, {
                        headers: {
                            "Content-Type": "application/json",
                            "auth-token": this.token,
                        },
                    });
                    const resDB = await res.json();
                    this.usuarios = resDB.body;
                    this.empresas.forEach(element => {
                        const usuarioEmpresa = [];
                        resDB.body.forEach(lel => {
                            if (element.Uid == lel.Uid_empresa) {
                                usuarioEmpresa.push({
                                    id: lel.Uid,
                                    name: lel.nombre + ' ' + lel.apellido
                                });
                            }
                        })
                        item.children.push({
                            id: element.Uid,
                            name: element.nombre,
                            children: usuarioEmpresa
                        });
                    });
                } catch (error) {
                    console.log(error);
                }
            },

            async listarTipoTrat() {
                try {
                    await fetch(`${this.url_api}/tipo_tratamiento`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.tiposTratamientos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async listarTratamiento(tratamiento) {
                this.tratamientos = [];
                try {
                    await fetch(`${this.url_api}/tratamiento`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                response.body.forEach((element) => {
                                    if (element.tipo == this.tipoTratamiento) {
                                        this.tratamientos.push(element);
                                    }
                                    if (tratamiento) {
                                        if (tratamiento == element.Uid) {
                                            this.form.tratamiento = tratamiento;
                                        }
                                    }
                                });
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async listarDepartamento() {
                try {
                    await fetch(`${this.url_api}/departamento`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.departamentos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async listarMunicipio(municipio) {
                this.municipios = [];
                try {
                    await fetch(`${this.url_api}/municipio`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                response.body.forEach((element) => {
                                    if (element.departamento == this.departamento) {
                                        this.municipios.push(element);
                                    }
                                    if (municipio) {
                                        if (municipio == element.Uid) {
                                            this.form.municipio = municipio;
                                        }
                                    }
                                });
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async listarCargo() {
                try {
                    await fetch(`${this.url_api}/cargo`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.cargos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async listarEmpresa() {
                try {
                    await fetch(`${this.url_api}/empresa`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.empresas = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async listarSede(sede) {
                this.sedes = [];
                try {
                    await fetch(`${this.url_api}/sede`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                response.body.forEach(element => {
                                    if (element.Uid_empresa == this.empresa) {
                                        this.sedes.push(element);
                                    }
                                    if (sede) {
                                        if (sede == element.Uid) {
                                            this.form.sede = sede;
                                        }
                                    }
                                })
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async listarRol() {
                try {
                    await fetch(`${this.url_api}/rol`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.roles = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async listarGrupo() {
                try {
                    await fetch(`${this.url_api}/grupo`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.grupos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            async estado(usuario) {
                let estadoO = usuario.estado;
                if (usuario.estado == 0) {
                    usuario.estado = 1;
                } else {
                    usuario.estado = 0;
                }
                try {
                    await fetch(
                            `${this.url_api}/persona/estado/${usuario.Uid_usuario}`, {
                                method: 'POST', // or 'PUT'
                                body: JSON.stringify({
                                    estado: usuario.estado
                                }), // data can be `string` or {object}!
                                headers: {
                                    'Content-Type': 'application/json',
                                    "auth-token": this.token
                                }
                            })
                        .then(res => res.json())
                        .catch(error => {
                            console.error('Error:', error);
                            usuario.estado = estadoO;
                        })
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Estado actualizado con Exito!';
                                this.colorAlerta = 'purple darken-4'
                                this.alerta = true;
                                this.listarUsuario();
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al actualizar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                    usuario.estado = estadoO;
                }
            },

            editarUsuario(usuario) {
                this.editedUsuarioIndex = this.usuarios.indexOf(
                    usuario)
                this.form = Object.assign({}, usuario)
                this.dialog = true
                this.UidUsuario = usuario.Uid;


                if (usuario.estado == 1) {
                    this.form.estadoU = true
                }

                this.roles.forEach(element => {
                    if (element.Uid == usuario
                        .Uid_rol) {
                        this.form.rol = usuario.Uid_rol
                    }
                })

                this.grupos.forEach(element => {
                    if (element.Uid == usuario
                        .Uid_grupo) {
                        this.form.grupo = usuario
                            .Uid_grupo
                    }
                })

                this.cargos.forEach(element => {
                    if (element.Uid == usuario
                        .Uid_cargo) {
                        this.form.cargo = usuario
                            .Uid_cargo
                    }
                })

                this.empresas.forEach(element => {
                    if (element.Uid == usuario
                        .Uid_empresa) {
                        this.empresa = usuario
                            .Uid_empresa
                        this.listarSede(usuario
                            .Uid_sede)
                    }
                })

                this.tiposTratamientos.forEach(element => {
                    if (element.Uid == usuario
                        .Uid_tipoTratamiento) {
                        this.tipoTratamiento = usuario
                            .Uid_tipoTratamiento
                        this.listarTratamiento(usuario
                            .Uid_tratamiento)
                    }
                })

                this.departamentos.forEach(element => {
                    if (element.Uid == usuario
                        .Uid_departamento) {
                        this.departamento = usuario
                            .Uid_departamento
                        this.listarMunicipio(usuario
                            .Uid_municipio);
                    }
                })
                this.form.contrasena = "";
                this.form.contrasenaC = "";
            },

            cancelarUsuario() {
                this.$refs.frm.reset()
                this.$refs.frm.resetValidation()
                this.dialog = false
                this.$nextTick(() => {
                    this.form = Object.assign({}, this
                        .defaultForm)
                    this.editedUsuarioIndex = -1
                })
            },

            async submit() {
                if (!this.form.estadoU) {
                    this.form.estadoU = 0
                }
                if (this.form.estadoU) {
                    this.form.estadoU = 1
                }
                if (this.$refs.frm.validate()) {
                    this.snackbar = true;
                    if (this.editedUsuarioIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/persona/${this.UidUsuario}`, {
                                    method: 'POST', // or 'PUT'
                                    body: JSON.stringify(this.form), // data can be `string` or {object}!
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    }
                                })
                                .then(res => res.json())
                                .catch(error => console
                                    .error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        this.listarUsuario();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error)
                        }
                    } else {
                        try {
                            await fetch(`${this.url_api}/persona`, {
                                    method: 'POST', // or 'PUT'
                                    body: JSON
                                        .stringify(this.form), // data can be `string` or {object}!
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    }
                                })
                                .then(res => res.json())
                                .catch(error => console
                                    .error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarUsuario();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error)
                        }
                    }
                    this.cancelarUsuario()
                }
            },
        },
    };
</script>